<template>
  <v-container fluid class="">
    <v-form ref="form">
      <h3 id="tituloCarga" class="text-center" color="white">
        DATOS PRINCIPALES
      </h3>
      <div class="row mt-3 mx-1">
        <div class="col-12 py-1">
          <v-autocomplete
            :items="marketingList"
            label="Tipo de Marketing"
            dense
            search
            v-model="datosPrincipales.idmarketing"
            :clearable="tipo != 'ver'"
            :readonly="tipo == 'ver'"
            :rules="[(v) => !!v || 'El Tipo de Marketing es requerido']"
          ></v-autocomplete>
        </div>
        <!-- <v-card-text class="mt-3 px-5 py-1"> -->
        <div class="col-12 py-1" v-if="!precotizacionFlag && tipo != 'ver'">
          <v-autocomplete
            auto-select-first
            :items="status.filter((v) => v.value != 1)"
            label="Estado de la Cotización"
            dense
            v-model="datosPrincipales.idstatus"
            :clearable="tipo != 'ver'"
            :readonly="tipo == 'ver'"
            :rules="[(v) => !!v || 'El estado es requerido']"
            @change="registrarLlamada()"
          ></v-autocomplete>
        </div>
        <div class="col-12 py-1" v-if="tipo == 'ver'">
          <v-autocomplete
            auto-select-first
            :items="status"
            label="Estado de la Cotización"
            dense
            v-model="datosPrincipales.idstatus"
            :clearable="tipo != 'ver'"
            :readonly="tipo == 'ver'"
            :rules="[(v) => !!v || 'El estado es requerido']"
          ></v-autocomplete>
        </div>
        <div class="col-12 py-1">
          <v-autocomplete
            :items="entities"
            label="Ejecutivo."
            dense
            search
            v-model="datosPrincipales.identities"
            :clearable="tipo != 'ver'"
            :readonly="tipo == 'ver'"
          ></v-autocomplete>
        </div>
        <div class="col-12 py-1">
          <v-autocomplete
            :items="entities"
            label="Pricing"
            dense
            search
            v-model="datosPrincipales.idPricing"
            :clearable="tipo != 'ver'"
            :readonly="tipo == 'ver'"
          ></v-autocomplete>
        </div>

        <div class="col-12 py-1">
          <v-text-field
            label="Nombres"
            placeholder="Nombres..."
            :append-outer-icon="'mdi-account-search'"
            @click:append-outer="buscarClienteDialog = true"
            @click="buscarClienteDialog = true"
            :rules="[(v) => !!v || 'El nombre es requerido']"
            dense
            requerid
            autocomplete="off"
            id="nombre"
            name="nombre"
            :clearable="tipo != 'ver'"
            :readonly="tipo == 'ver'"
            v-model="datosPrincipales.name"
            v-if="tipo != 'ver'"
          ></v-text-field>
          <v-text-field
            label="Nombres"
            placeholder="Nombres..."
            dense
            requerid
            autocomplete="off"
            id="nombre"
            name="nombre"
            :clearable="tipo != 'ver'"
            :readonly="tipo == 'ver'"
            v-model="datosPrincipales.name"
            v-if="tipo == 'ver'"
          >
          </v-text-field>
        </div>
        <div class="col-12 py-1">
          <v-text-field
            label="Teléfono (No Editable)"
            type="tel"
            dense
            placeholder="Teléfono..."
            autocomplete="off"
            v-model="datosPrincipales.phone"
            readonly
          >
          </v-text-field>
        </div>
        <!-- Datos Cliente -->
        <div class="col-12 py-1">
          <v-autocomplete
            :items="modality"
            label="Sentido*"
            v-model="datosPrincipales.idmodality"
            :rules="[(v) => !!v || 'El sentido es requerido']"
            dense
            :clearable="tipo != 'ver'"
            :readonly="tipo == 'ver'"
          ></v-autocomplete>
        </div>
        <div class="col-12 py-1">
          <v-autocomplete
            :items="shipment"
            label="Carga*"
            dense
            v-model="datosPrincipales.idshipment"
            :rules="[(v) => !!v || 'El tipo de carga es requerido']"
            return-object
            :clearable="tipo != 'ver'"
            :readonly="tipo == 'ver'"
          ></v-autocomplete>
        </div>
        <div class="col-12 py-1">
          <v-autocomplete
            :items="incoterm"
            label="Incoterm*"
            dense
            v-model="datosPrincipales.idincoterm"
            :readonly="tipo == 'ver'"
            :rules="[(v) => !!v || 'El icoterms es requerido']"
          ></v-autocomplete>
        </div>
        <div
          class="col-12 py-1"
          v-if="
            incoterm.filter(
              (v) => v.value == datosPrincipales.idincoterm && v.name == 'EXW'
            ).length > 0
          "
        >
          <v-text-field
            name="name"
            label="Nombre del proveedor"
            id="id"
            v-model="datosPrincipales.proveedor"
            dense
          ></v-text-field>
        </div>
        <div
          class="col-12 py-1"
          v-if="
            incoterm.filter(
              (v) => v.value == datosPrincipales.idincoterm && v.name == 'EXW'
            ).length > 0
          "
        >
          <v-text-field
            name="Teléfono"
            label="Teléfono del proveedor"
            v-model="datosPrincipales.telefonoproveedor"
            dense
          ></v-text-field>
        </div>
        <div
          class="col-12 py-1"
          v-if="
            incoterm.filter(
              (v) => v.value == datosPrincipales.idincoterm && v.name == 'EXW'
            ).length > 0
          "
        >
          <v-text-field
            name="name"
            label="Dirección del proveedor"
            id="id"
            v-model="datosPrincipales.direccionproveedor"
            dense
          ></v-text-field>
        </div>
        <div class="col-12" v-if="tipo == 'nuevo' || tipo == 'precotizacion'">
          <v-flex text-right>
            <v-btn
              class="mx-1"
              color="#5AB55E"
              small
              scrollable
              @click="aceptar()"
            >
              <b>PASO 2</b> <v-icon class="">mdi-chevron-right</v-icon>
            </v-btn>
          </v-flex>
        </div>
        <!-- <div class="col-12" v-if="tipo == 'editar'">
          <v-flex text-right>
            <v-btn
              small
              color="#FB9514"
              block
              x-small
              class="ml-5 mr-5"
              @click="aceptar"
            >
              RE - CARGAR
              <v-icon class="mx-1">mdi-refresh </v-icon>
            </v-btn>
          </v-flex>
        </div> -->
      </div>
    </v-form>

    <!-- MODALES -->
    <v-dialog v-model="buscarClienteDialog" max-width="700px">
      <v-card class="pa-10">
        <buscarCliente />
      </v-card>
    </v-dialog>
    <!--  -->
    <v-dialog v-model="dialog" persistent width="400">
      <v-card color="info" dark class="py-5">
        <v-card-text>
          Un momento por favor
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="llamadaFlag" max-width="600px">
      <RegistrarLlamada :id="id" />
    </v-dialog>
  </v-container>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import funcion from "@/mixins/funciones";
export default {
  props: ["tipo"],
  name: "cardDatosPersonalesComponent",
  components: {
    buscarCliente: () =>
      import("../../components/comun/buscarClienteComponent.vue"),
    RegistrarLlamada: () =>
      import("../../components/comun/registroLlamadaComponent.vue"),
  },
  mixins: [funcion],
  data() {
    return {
      items: [],
      formulacio: [],
      buscarClienteDialog: false,
      disable: true,
      llamadaFlag: false,
      id: parseInt(this.$route.params.id),
    };
  },
  mounted() {
    this.setIdQuote(this.$route.params.id);
  },
  created() {},
  methods: {
    ...mapMutations([
      "updateDataCarga",
      "setDialog",
      "setRecargarFlag",
      "setIdQuote",
    ]),
    ...mapActions(["obtenerListadoLlamadas"]),
    aceptar() {
      if (this.$refs.form.validate()) {
        this.updateDataCarga();
      }
    },
    registrarLlamada() {
      if (this.tipo == "editar") {
        this.llamadaFlag = true;
      }
    },
  },

  computed: {
    ...mapState([
      "status",
      "entities",
      "modality",
      "shipment",
      "incoterm",
      "usuarioSeleccionadoFlag",
      "datosPrincipales",
      "dialog",
      "nuevoUsuarioFlag",
      "precotizacionFlag",
      "marketingList",
      "flag",
    ]),
  },

  watch: {
    usuarioSeleccionadoFlag() {
      this.buscarClienteDialog = false;
    },
    nuevoUsuarioFlag() {
      this.buscarClienteDialog = false;
    },
    flag() {
      this.llamadaFlag = false;
      this.obtenerListadoLlamadas(this.$route.params.id);
    },
  },
};
</script>
<style scoped></style>
